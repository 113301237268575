<template>
  <EventListContainer :loading />
</template>

<script setup>
import { useHead, useNuxtApp, useRoute } from '#imports';
import { computed, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import { useIntranet } from '@/composables/useIntranet';
import { getMetadataAndTags } from '../../helpers/metadata';
import { clientWorkWindowEvent } from '../../helpers/event-dispatch-helpers.js';
import EventListContainer from '../../components/events/EventListContainer';

const route = useRoute();
const store = useStore();
const metaDataInfo = getMetadataAndTags(store.state.settings.metadata, 'events', 'Events');
const loading = computed(() => store.getters['events/isLoading']);
const $axios = useNuxtApp().$axios;
const { handleLayout } = useIntranet();

useHead({
  ...metaDataInfo.metaTitle,
  meta: metaDataInfo.metaTags,
});

onMounted(async () => {
  handleLayout();

  store.dispatch('events/filterView', route.query.view);
  await store.dispatch('events/buildEventComponentData', {
    $axios,
  });

  clientWorkWindowEvent('events-loaded');
});

watch(
  () => route.query,
  async () => {
    store.dispatch('events/filterView', route.query.view);
    await store.dispatch('events/buildEventComponentData', {
      $axios,
    });
    clientWorkWindowEvent('events-loaded');
  },
);
</script>
